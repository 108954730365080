<template>
  <div :class="{ itemActive: num == 0 && max }">
    <div class="foodItem" :class="{ active: selected }">
      <span
        @click="clickFood"
        class="selectIcon"
        :class="{ active: selected }"
      ></span>
      <img
        @click="clickFood"
        v-if="lanType == 1"
        :src="foodInfo.logo || icon"
        alt=""
      />
      <img @click="clickFood" v-else :src="foodInfo.logoEn" alt="" />
      <p v-if="foodInfo.price">
        + {{ $store.state.money[$store.state.area] }} {{ foodInfo.price }}
      </p>
      <p v-if="lanType == 1">{{ foodInfo.name }}</p>
      <p v-else>{{ foodInfo.nameEn || foodInfo.nameTw }}</p>

      <div class="foodNum" v-if="showNum && selected">
        <span @click="male">-</span>
        <input
          @input="changeNum"
          @blur="changeStatus"
          type="text"
          v-model="num"
        />
        <span @click="add" class="foodNumAdd" :class="{ active: max }">+</span>
      </div>
      <!-- showNum && selected && foodInfo.property -->
      <!-- <p
        v-if="showNum && selected && foodInfo.property"
        class="setBtn"
        @click="selectFood"
      >
        定製
      </p> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "SetMealSkuCon",
  data() {
    return {
      lanType: this.$store.state.language,
      num: this.foodNum,
      icon: require("../../assets/icon/linshi.png"),
      selected: false,
      max: false,
      maxNum: null,
      hasNowNum: 0,
      hasInputNum: 0,
    };
  },
  props: {
    showNum: {
      type: Boolean,
      default: true,
    },
    foodInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    foodNum: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changeActive(type, maxNum, hasNowNum) {
      this.hasInputNum = Number(this.num);
      this.max = type;
      this.maxNum = maxNum;
      this.hasNowNum = hasNowNum;
    },
    changeStatus() {
      if (this.num == 0) {
        this.selected = false;
      }
    },
    changeNum() {
      this.num = parseInt(this.num) || 0;
      let N= this.maxNum - this.hasNowNum + this.hasInputNum
      this.num >= N
        ? (this.num = N)
        : false;
      this.num < 0 ? (this.num = 0) : false;
      var food = this.foodInfo;
      food.num = this.num || 0;
      food.selected = this.selected;
      this.$emit("selectNum", food);
      this.$emit("selectFood", this.foodInfo);
      // this.dataLayerAddOn(food);
    },
    addFood() {
      if (this.foodInfo.groupB) return;
      var food = this.foodInfo;
      food.num = this.num;
      this.$emit("selectNum", food);
    },
    //加
    add() {
      if (this.max) return;
      if (this.foodInfo.groupB) return;
      this.num++;
      var food = this.foodInfo;
      food.num = this.num;
      food.selected = this.selected;
      this.$emit("selectNum", food);
      this.$emit("selectFood", this.foodInfo);
      // this.dataLayerAddOn(food);
    },
    // 减
    male() {
      if (this.foodInfo.groupB) return;
      if (this.num == 0) {
        this.selected = false;
        return;
      }
      this.num--;
      if (this.num == 0) {
        this.selected = false;
      }
      var food = this.foodInfo;
      food.num = this.num;
      food.selected = this.selected;
      this.$emit("selectNum", food);
      // if (this.num) {
      this.$emit("selectFood", this.foodInfo);
      // }
    },
    // 打开选择规格的弹窗
    selectFood() {
      this.$emit("selectFood", this.foodInfo);
    },
    // 选中食物
    clickFood() {
      if (this.foodInfo.groupB) return;
      if (this.max) {
        if (!this.selected) {
          this.$emit("showTopNum");
          return;
        }
        // this.num = 0;
        // var food1 = this.foodInfo;
        // food1.num = this.num;
        // food1.selected = this.selected;
        // this.$emit("selectNum", food1);
        // this.selected = false;
        // return;
      }
      this.selected = !this.selected;
      if (this.num == 0 && !this.max) {
        this.num = 1;
      }
      if (!this.selected) {
        this.num = 0;
      }
      var food = this.foodInfo;
      food.num = this.num;
      food.selected = this.selected;
      this.$emit("selectNum", food);
      // if (this.num) {
      this.$emit("selectFood", this.foodInfo);
      // }
    },
    dataLayerAddOn(info) {
      if (!info.price) return;
      var T = this.$store.state.language;
      var ecommerce = {
        Item_name: T == 1 ? info.name : info.nameEn,
        price: info.price,
        Item_list_name: "add_on",
        Item_list_ID: info.pid,
        quantity: info.num,
        item_id: info.pid,
        Item_variant: info.memo,
        rec_usage: "",
        Items_category: "",
      };
      window.dataLayer.push({
        event: "combo_selection_details",
        ecommerce,
      });
    },
  },

  created() {
    if (this.foodInfo.groupB) {
      this.selected = true;
    }
    if (this.num != 0) {
      this.selected = true;
      this.addFood();
    }
  },
};
</script>
<style scoped>
.itemActive {
  opacity: 0.6;
}
.foodItem {
  width: 1.06rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 0.04rem;
}
.foodItem img {
  width: 1.06rem;
  height: 0.8rem;
  object-fit: cover;
}
.foodItem p {
  width: 100%;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #131314;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  margin: 0.04rem 0;
  padding: 0 0.04rem;
  box-sizing: border-box;
}
.foodNum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.foodNum span {
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #e4022b;
  text-align: center;
  line-height: 0.24rem;
  font-size: 0.2rem;
  color: #fff;
  background-color: #e4022b;
  font-weight: 500;
}
.foodNum input {
  width: 0.25rem;
  height: 0.24rem;
  text-align: center;
  border: none;
  outline: none;
  font-size: 0.16rem;
  margin: 0 0.05rem;
  border-radius: 0.02rem;
}
.foodItem.active {
  background-color: #f2f3f5;
}
.selectIcon {
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  top: 0.04rem;
  right: 0.04rem;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  z-index: 999;
}
.selectIcon.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.foodNumAdd.active {
  background-color: #fff;
  border-color: #e4022b;
  color: #e4022b;
}
.setBtn {
  width: 80% !important;
  height: 0.2rem;
  text-align: center;
  line-height: 0.2rem;
  border-radius: 0.02rem;
  background-color: #e4022b;
  color: #fff !important;
}
</style>