<template>
  <div class="skuItem">
    <p class="index" v-show="num">
      <!-- {{
        $store.state.language == 1
          ? "第 " + (index + 1) + " 杯"
          : index + 1 + " Cup"
      }} -->
    </p>
    <div v-for="(skuIt, index) in skuInfo" :key="index" :id="'food_' + index">
      <p class="title">
        {{ $store.state.language == 1 ? skuIt.title : skuIt.titleEn }}
      </p>
      <div class="sku">
        <span
          @click="changeSku(item, skuIt.uid, index)"
          v-for="item in skuIt.items"
          :key="item.uid"
          :class="{ active: activeObj[index] == item.uid }"
          >{{ $store.state.language == 1 ? item.name : item.nameEn }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FoodSku",
  data() {
    return {
      id: 0,
      productInfo: {
        index: this.index,
        num: 1,
        propertys: [],
      },
      activeObj: {},
    };
  },
  props: {
    num: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    skuInfo: {
      type: Object,
      default() {
        return {
          title: "",
          items: [],
        };
      },
    },
  },
  methods: {
    changeSku(item, skuItUid, itemIndex) {
      var obj = {
        pid: skuItUid,
        items: [item],
      };
      this.activeObj[itemIndex] = item.uid;
      this.id = item.uid;
      this.productInfo.propertys[itemIndex] = obj;
      // var index = -1;
      // for (var i = 0; i < this.productInfo.propertys.length; i++) {
      //   if (this.productInfo.propertys[i].pid == skuItUid) {
      //     index = i;
      //   }
      // }
      // if (index != -1) {
      //   this.productInfo.propertys[index] = obj;
      // } else {
      //   if(this.skuInfo.length==1){
      //     this.productInfo.propertys=[]
      //   }
      //   this.productInfo.propertys.push(obj);
      // }
      if(!this.productInfo.pidData){
        this.productInfo.pidData=[]
      }
      this.productInfo.pidData[itemIndex]=item.uid
      this.$emit("selectSku", this.productInfo);
    },
    defortSelect() {
      for (let i = 0; i < this.skuInfo.length; i++) {
        this.changeSku(
          this.skuInfo[i].items[0],
          this.skuInfo[i].items[0].uid,
          i
        );
      }
    },
    clearSku() {
      this.productInfo.propertys = [];
    },
  },
  watch: {
    skuInfo() {
      // this.defortSelect();
    },
  },
  created() {},
};
</script>
<style scoped>
.title {
  width: 100%;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #474747;
  text-align: left;
  margin-bottom: 0.12rem;
}
.skuItem {
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.sku {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.sku span {
  width: 1.02rem;
  height: 0.44rem;
  border: 1px solid #03060d;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44rem;
  color: #03060d;
  text-align: center;
  border-radius: 0.08rem;
}
.sku span.active {
  color: #e4022b;
  border: 1px solid #e4022b;
}
.sku span:nth-child(3n) {
  margin-left: 0;
}
.index {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.2rem;
  color: #03060d;
  margin-bottom: 0.08rem;
  text-align: left;
}
</style>