<template>
  <div class="detailPage">
    <topbar bgColor="#F5F5F5"></topbar>
    <div class="cover">
      <img v-if="lanType == 1" :src="foodDetail.logo" alt="" />
      <img v-else :src="foodDetail.logoEn" alt="" />
    </div>
    <div class="foodNum">
      <p class="name" v-if="lanType == 1">{{ foodDetail.name }}</p>
      <p class="name" v-else>{{ foodDetail.nameEn || foodDetail.nameTw }}</p>

      <div class="num">
        <span :class="{ active: num <= 1 }" @click="changeNum(1)">-</span>
        <input
          :readonly="foodDetail.isCouponProType ? true : false"
          type="text"
          ref="numInput"
          v-model="num"
          @input="changeNum(3)"
          @focus="changeClass(1)"
          @blur="changeClass(2)"
        />
        <span @click="changeNum(2)" :class="{ active: none }">+</span>
      </div>
    </div>
    <div v-if="foodDetail.desc || foodDetail.descEn">
      <div class="foodDesc" v-if="lanType == 1">{{ foodDetail.desc }}</div>
      <div class="foodDesc" v-else>{{ foodDetail.descEn }}</div>
    </div>
    <div class="skuBox" v-if="foodDetail.type == 1">
      <div
        class="skuItem"
        v-for="(item, index) in skuNum"
        :key="index"
      >
        <food-sku
          :ref="'sku_' + index"
          @selectSku="selectSku"
          :num="num"
          :index="index"
          :skuInfo="skuInfo"
        ></food-sku>
      </div>
    </div>
    <div class="skuBox" v-else>
      <set-meal-sku
        @getSku="getSku"
        :skuInfo="mealDetail"
        :index="0"
        :mealNum="num"
        :updateSkuList="updateSkuList"
        :update="update"
        ref="mealSku"
      ></set-meal-sku>
    </div>
    <div class="priceBtn" @click="addCart">
      <p>
        <b>{{ num }}</b>
        {{ addBtnText }}
      </p>
      <span
        >{{ $store.state.money[$store.state.area] }}
        {{ foodDetail.price * num + totalPrice * num }}</span
      >
    </div>
    <confirm-box
      v-show="showErr"
      @finish="finish"
      :title="cart_none"
      :subTitle="cart_none_B"
      :subTitleShow="true"
      :size="16"
      :finishBtn="finishBtn"
    ></confirm-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import FoodSku from "../../components/food/FoodSku.vue";
import SetMealSku from "../../components/food/SetMealSku.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import { postDC } from "../../assets/utils/request";
import { copyData } from "../../assets/utils/copy";
import L from "../../assets/js/language";
export default {
  name: "FoodDetail",
  data() {
    return {
      foodDetail: {},
      busindessType: null,
      mealDetail: null,
      isProSoldout: false,
      isProSoldoutName: "",
      showErr: false,
      listRequirements: [], //單品sku
      num: 1,
      none: false,
      skuNum: [1],
      totalPrice: 0,
      skuInfo: {
        title: "",
        items: [],
      }, //规格
      lanType: 1, //语言类型
      foodType: 1, //餐品类型
      mains: {}, //套餐
      canAdd: false,
      canClear: true,
      updateSkuList: {},
      update: false,
      cart_none: "",
      cart_none_B: "",
      HQXXSB: "",
      QXZSPGG: "",
      JRGWC: "",
      GXGWUC: "",
      BZCD: "",
    };
  },
  methods: {
    finish() {
      this.showErr = false;
    },
    changeClass(type) {
      type == 1
        ? this.$refs.numInput.classList.add("active")
        : this.$refs.numInput.classList.remove("active");
    },
    changeNum(type) {
      if (this.foodDetail.isCouponProType) return;
      this.num = parseInt(this.num);
      if (type == 1 && this.num > 1) {
        this.num--;
      } else if (type == 2 && !this.none) {
        this.num++;
      } else if (type == 3) {
        if (!this.num) {
          return;
        }
        if (this.num < 0) {
          this.num = 1;
        }
        if (this.num > 99) {
          this.num = 99;
        }
      }
      this.skuNum = [];
      for (var i = 0; i < this.num; i++) {
        this.skuNum.push(i);
      }
    },
    // 接受单品sku
    selectSku(info) {
      var index = -1;
      for (var i = 0; i < this.foodDetail.listRequirements.length; i++) {
        if (this.foodDetail.listRequirements[i].index == info.index) {
          index = i;
        }
      }
      if (index != -1) {
        this.foodDetail.listRequirements[index] = info;
        this.listRequirements[index] = info;
      } else {
        this.foodDetail.listRequirements.push(info);
        this.listRequirements.push(info);
      }
    },
    // 接受套餐的sku详情
    getSku(info) {
      this.totalPrice = info.myPrice;
      if (this.foodDetail.isCouponProType) {
        this.totalPrice = 0;
      }
      // 主食
      var mainObj = {
        pid: this.foodDetail.uid,
        weight: 0,
        name: this.foodDetail.name,
        num: this.foodDetail.num || 1,
        typeId: this.foodDetail.type,
        price: this.foodDetail.price,
        groups: [
          {
            index: 0,
            mains: [
              {
                groups: [
                  {
                    products: [],
                  },
                ],
              },
            ],
          },
        ],
      };
      mainObj.groups[info.index].index = info.index;
      mainObj.groups[info.index].mains = [];
      mainObj.groups[info.index].mains[0] = {};
      mainObj.groups[info.index].mains[0].groups = [];
      mainObj.groups[info.index].mains[0].groups[0] = {};
      mainObj.groups[info.index].mains[0].groups[0].type = 2;
      mainObj.groups[info.index].mains[0].groups[0].products = [];
      mainObj.groups[info.index].mains[0].groups[1] = {};
      mainObj.groups[info.index].mains[0].groups[1].type = 4;
      mainObj.groups[info.index].mains[0].groups[1].products = [];
      for (var k = 0; k < info.listPropertys.length; k++) {
        if (info.listPropertys[k].groupB) {
          mainObj.groups[info.index].mains[0].groups[0].products.push(
            info.listPropertys[k]
          );
        } else {
          mainObj.groups[info.index].mains[0].groups[1].products.push(
            info.listPropertys[k]
          );
        }
      }
      var money = 0;
      for (var i = 0; i < info.listPropertys.length; i++) {
        money += info.listPropertys[i].price * info.listPropertys[i].num;
      }
      mainObj.price = this.foodDetail.price + money;
      this.foodDetail.selectedFood = mainObj;
    },
    // 添加到购物车
    addCart() {
      // if (!this.canAdd) return;
      // if (this.foodDetail.type != 1) {
      //   var can = this.$refs.mealSku.closeSku("", true);
      //   if (!can) {
      //     return this.$toast("请选择规格");
      //   }
      // }
      if (this.isProSoldout) {
        this.$toast(this.BZCD);
        return;
      }
      if (this.foodDetail.type != 1) {
        var res = this.$refs.mealSku.checkFoodNum();
        if (res) {
          this.showErr = true;
          return;
        }
      }
      if (this.num == 0) {
        return;
      }
      if (
        this.foodDetail.type == 1 &&
        this.foodDetail.listRequirements.length != this.num &&
        this.foodDetail.property
      ) {
        return this.$toast(this.QXZSPGG);
      }
      if (this.foodDetail.type != 1) {
        if (
          !this.foodDetail.selectedFood ||
          !this.foodDetail.selectedFood.groups ||
          !this.foodDetail.selectedFood.groups.length
        ) {
          return;
        }
        for (
          var sel = 0;
          sel < this.foodDetail.selectedFood.groups.length;
          sel++
        ) {
          var selMains = this.foodDetail.selectedFood.groups[sel].mains;
          for (var selM = 0; selM < selMains.length; selM++) {
            var mGroup = selMains[selM].groups;
            for (var selG = 0; selG < mGroup.length; selG++) {
              var selPro = mGroup[selG].products;
              for (var selP = 0; selP < selPro.length; selP++) {
                if (selPro[selP].groupB) {
                  selPro[selP].num = selPro[selP].maxNum;
                }
              }
            }
          }
        }
      }
      this.foodDetail.num = this.num;
      var pro = this.foodDetail;
      pro.num = this.num;
      if (pro.isCouponProType == 5 || pro.isCouponProType == 3) {
        if (pro.type == 2) {
          pro.selectedFood.price = pro.price;
        }
      }
      var product = {
        shopId: this.foodDetail.storeId,
        update: this.update,
        product: pro,
      };
      if (this.foodDetail.type != 1) {
        product.product.pro = this.mealDetail;
        this.$store.commit("createCart", product);
        this.dataLayerPush(product.product.selectedFood);
        this.dataLayerPushDetail(product.product.selectedFood);
      } else {
        for (var k = 0; k < this.listRequirements.length; k++) {
          var copyResult = this.copyData(product);
          copyResult.product.num = 1;
          copyResult.product.listRequirements = [];
          copyResult.product.listRequirements.push(this.listRequirements[k]);
          this.$store.commit("createCart", copyResult);
          this.dataLayerPush(copyResult.product.selectedFood);
          this.dataLayerPushSingle();
        }
      }
      this.canClear = false;
      // this.$store.commit("createCart", product);

      this.$router.back(-1);
    },
    // 深拷貝
    copyData(source) {
      var result = {};
      for (var key in source) {
        result[key] =
          typeof source[key] === "object"
            ? this.copyData(source[key])
            : source[key];
      }
      return result;
    },
    // 获取餐品详情
    async getFoodDetail() {
      this.foodDetail.listRequirements = [];
      if (this.foodDetail.type == 1) {
        if (this.foodDetail.property && this.foodDetail.property.propertys) {
          this.skuInfo = this.foodDetail.property.propertys;
          // this.skuInfo = this.foodDetail.property.standard;
          this.$nextTick(() => {
            for (let i = 0; i < this.skuNum; i++) {
              this.$refs["sku_" + i].defortSelect();
            }
          });
        }
        this.canAdd = true;
      }
      if (this.foodDetail.type != 2 && this.foodDetail.type != 3) {
        return;
      }
      let params = {
        actionName: "candao.product.getGroupData",
        content: {
          storeId: this.foodDetail.storeId,
          menuId: this.foodDetail.menuId,
          pid: this.foodDetail.uid,
          sendType: this.busindessType,
        },
      };
      let foodDetail = await postDC("Action", params);

      if (foodDetail.status == 1 && foodDetail.data) {
        var food = foodDetail.data.mainFoodAreas;
        for (var i = 0; i < food.length; i++) {
          var foodItem = food[i].associatedGroups;
          for (var key in foodItem) {
            if (foodItem[key].groupB) {
              let groupBCell = foodItem[key].groupB.cells;
              groupBCell.forEach((v) => {
                if (v.isSoldOut) {
                  this.isProSoldout = true;
                  this.isProSoldoutName =
                    this.$store.state.language == 1 ? v.name : v.nameEn;
                  return;
                }
              });
            }
            if (foodItem[key].groupD) {
              var groupD = foodItem[key].groupD.items;
              for (var g = 0; g < groupD.length; g++) {
                var items = groupD[g].cells;
                var canUseItem = [];
                for (var it = 0; it < items.length; it++) {
                  if (!items[it].isSoldOut && items[it].status == 1) {
                    items[it].indexItem = "groupD_" + g + "_" + it;
                    canUseItem.push(items[it]);
                  }
                  groupD[g].cells = canUseItem;
                }
              }
            }
          }
        }
        this.mealDetail = foodDetail.data;
        this.canAdd = true;
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    setUpdateSkuList() {
      this.updateSkuList = {};
      var food = this.foodDetail.selectedFood.groups;
      for (var i = 0; i < food.length; i++) {
        var mains = food[i].mains;
        for (var m = 0; m < mains.length; m++) {
          var mGroups = mains[m].groups;
          for (var g = 0; g < mGroups.length; g++) {
            var products = mGroups[g].products;
            for (var p = 0; p < products.length; p++) {
              this.updateSkuList[
                products[p].pid + "_" + products[p].indexItem
              ] = products[p];
            }
          }
        }
      }
    },
    // 埋点 添加购物车
    dataLayerPush(pro) {
      var items = [];
      if (this.foodDetail.type != 1) {
        var groups = pro.groups;
        groups.forEach((p) => {
          p.mains.forEach((m) => {
            m.groups.forEach((g) => {
              g.products.forEach((ps) => {
                var itemObj = {};
                itemObj.item_name = this.lanType == 1 ? ps.name : ps.nameEn;
                itemObj.item_id = ps.pid;
                itemObj.price = ps.price;
                itemObj.addon_org_price = 0;
                itemObj.item_list_name = this.foodDetail.bigTypeName;
                itemObj.item_list_id = this.foodDetail.bigTypeId;
                itemObj.quantity = ps.num;
                itemObj.rec_usage = "";
                itemObj.item_category = "";
                itemObj.item_variant = ps.memo;
                items.push(itemObj);
              });
            });
          });
        });
      } else {
        var itemObj = {};
        itemObj.item_name =
          this.lanType == 1 ? this.foodDetail.name : this.foodDetail.nameEn;
        itemObj.item_id = this.foodDetail.uid;
        itemObj.price = this.foodDetail.price;
        itemObj.addon_org_price = 0;
        itemObj.item_list_name = this.foodDetail.bigTypeName;
        itemObj.item_list_id = this.foodDetail.bigTypeId;
        itemObj.quantity = this.num;
        itemObj.rec_usage = "";
        itemObj.item_category = "";
        itemObj.item_variant = this.foodDetail.memo;
        items.push(itemObj);
      }
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items,
        },
      });
    },
    // 埋点 进入页面 餐品详情 套餐
    dataLayerPushDetail(pro) {
      // var itemList = [];
      // this.mealDetail.mainFoodAreas.forEach((mains) => {
      //   var group = mains.associatedGroups;
      //   for (var key in group) {
      //     for (var key2 in group[key]) {
      //       var items = group[key][key2].cells || group[key][key2].items;
      //       for (var g = 0; g < items.length; g++) {
      //         var cells = items[g].cells || items;
      //         for (var c = 0; c < cells.length; c++) {
      //           var itemObj = {};
      //           itemObj.item_name =
      //             this.lanType == 1 ? cells[c].name : cells[c].nameEn;
      //           itemObj.item_id = cells[c].pid;
      //           itemObj.combo_prem_price = cells[c].price;
      //           itemObj.item_list_name = this.foodDetail.bigTypeName;
      //           itemObj.item_list_id = this.foodDetail.bigTypeId;
      //           itemObj.quantity = 1;
      //           itemObj.index = c;
      //           itemObj.item_category = "";
      //           itemObj.item_variant =
      //             this.lanType == 1 ? cells[c].desc : cells[c].descEn;
      //           itemList.push(itemObj);
      //         }
      //       }
      //     }
      //   }
      // });
      let itemList = [];
      var groups = pro.groups;
      groups.forEach((p) => {
        p.mains.forEach((m) => {
          m.groups.forEach((g) => {
            g.products.forEach((ps) => {
              var itemObj = {};
              itemObj.item_name = this.lanType == 1 ? ps.name : ps.nameEn;
              itemObj.item_id = ps.pid;
              itemObj.price = ps.price;
              itemObj.addon_org_price = 0;
              itemObj.item_list_name = this.foodDetail.bigTypeName;
              itemObj.item_list_id = this.foodDetail.bigTypeId;
              itemObj.quantity = ps.num;
              itemObj.rec_usage = "";
              itemObj.item_category = "";
              itemObj.item_variant = this.lanType == 1 ? ps.desc : ps.descEn;
              itemList.push(itemObj);
            });
          });
        });
      });
      window.dataLayer.push({
        event: "combo_selection_details",
        ecommerce: {
          items: itemList,
        },
        page_category: "product",
      });
    },
    dataLayerPushSingle() {
      window.dataLayer.push({
        event: "customization",
        ecommerce: {
          Item_name:
            this.lanType == 1 ? this.foodDetail.name : this.foodDetail.nameEn,
          item_id: this.foodDetail.uid,
          item_list_name: this.foodDetail.bigTypeName,
          item_list_id: this.foodDetail.bigTypeId,
          Index: 1,
          custom_variant: this.lanType == this.foodDetail.desc,
        },
      });
    },
    setLanguage() {
      var T = "chinese";
      if (this.$store.state.language != 1) {
        T = "english";
      }
      this.cart_none = L.cart_none[T];
      this.cart_none_B = L.cart_none_B[T];
      this.finishBtn = L.A[T];
      this.HQXXSB = L.HQXXSB[T];
      this.QXZSPGG = L.QXZSPGG[T];
      this.JRGWC = L.JRGWC[T];
      this.GXGWUC = L.GXGWUC[T];
      this.BZCD = L.BZCD[T];
    },
  },
  computed: {
    addBtnText() {
      return this.update ? this.GXGWUC : this.JRGWC;
    },
  },
  created() {
    this.$store.commit("saveShowCoupon", true);

    this.lanType = this.$store.state.language;
    var info = this.$route.params;
    info = copyData(JSON.parse(info.info));
    this.busindessType = info.KFCbusinessType;
    if (!info.change) {
      info.isDetail = true;
      this.foodDetail = info;
      this.getFoodDetail();
    } else {
      this.update = true;
      var cart = copyData(this.$store.state.cart);
      // for (var i = 0; i < cart.length; i++) {
      // if (cart[i].uid == info.id) {
      this.foodDetail = cart[this.$store.state.updateProIndex];
      this.getFoodDetail();
      this.num = this.foodDetail.num;
      if (this.foodDetail.type != 1) {
        this.setUpdateSkuList();
      }
      // break;
      // }
      // }
    }
    this.setLanguage();
  },
  beforeRouteLeave() {
    if (this.canClear && this.foodDetail.isCouponPro) {
      if (!this.update) {
        this.$store.commit("saveShowCoupon", true);
        this.$store.commit("changeClearCou", true);
      }
    }
  },

  components: {
    Topbar,
    FoodSku,
    SetMealSku,
    ConfirmBox,
  },
};
</script>
<style scoped>
.detailPage {
  padding-bottom: 0.5rem;
}
.cover {
  width: 100%;
  height: 2.19rem;
  overflow: hidden;
}
.cover img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f5f5f5;
}
.foodNum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.2rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.18rem;
  color: #03060d;
  padding: 0.16rem 0.16rem 0.08rem;
  box-sizing: border-box;
  text-align: left;
  border-top-left-radius: 0.12rem;
  border-top-right-radius: 0.12rem;
  overflow: hidden;
  /* background-color: #e4022b; */
}
.foodNum .name {
  width: 2.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 0.28rem;
}
.num {
  display: flex;
  justify-content: center;
  align-items: center;
}
.num input {
  width: 0.24rem;
  height: 0.24rem;
  margin: 0 0.15rem;
  text-align: center;
  border: none;
  outline: none;
  font-size: 0.16rem;
  border-radius: 0.02rem;
}
.num input.active {
  background-color: rgba(242, 242, 242, 1);
}
.num span {
  width: 0.2rem;
  height: 0.2rem;
  border: 0.02rem solid #e4022b;
  color: #e4022b;
  border-radius: 0.04rem;
  line-height: 0.2rem;
  text-align: center;
  font-size: 0.16rem;
  background-color: #e4022b;
  color: #fff;
}
.num span.active {
  background-color: #fff;
  color: #e4022b;
}
.num span:last-child.active {
  background-color: rgba(129, 125, 125, 0.5);
  border-color: rgba(129, 125, 125, 0.5);
}
.foodDesc {
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #474747;
  text-align: justify;
  padding: 0.08rem 0.16rem 0.28rem;
  box-sizing: border-box;
}
.priceBtn {
  width: 3.43rem;
  height: 0.44rem;
  border-radius: 0.08rem;
  line-height: 0.44rem;
  text-align: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  background-color: #e4022b;
  position: fixed;
  bottom: 0.2rem;
  /* left: 0.175rem; */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.priceBtn p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.priceBtn p b {
  width: 0.22rem;
  height: 0.22rem;
  border-radius: 50%;
  text-align: center;
  line-height: 0.22rem;
  background-color: #fff;
  margin-right: 0.1rem;
  color: #e4022b;
}
.skuItem {
  margin-bottom: 0.28rem;
}
</style>