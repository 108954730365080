<template>
  <div class="setMealPage">
    <p class="title" v-show="showFoodList.length != 0">
      {{ $store.state.language == 1 ? "已包含餐品" : "Included Items" }}
    </p>
    <div class="selectedFood">
      <div class="selectedFoodItem" v-for="item in showFoodList" :key="item">
        <div class="cover">
          <!-- <img v-if="lanType == 1" :src="item.logo" alt="" />
          <img v-else :src="item.logoEn" alt="" /> -->
          <p v-if="lanType == 1">{{ item.name }}</p>
          <p v-else>{{ item.nameEn }}</p>
        </div>
        <p class="num">x {{ item.maxNum || 1 }}</p>
      </div>
    </div>
    <!-- 固定搭配 -->
    <p class="title guding" v-if="groupB">{{ groupB.title }}</p>
    <div class="mainFood guding" v-if="groupB">
      <div class="foodItem" v-for="(item, index) in groupB.cells" :key="index">
        <!-- <span class="selectIcon active"></span> -->
        <set-meal-sku-con
          @selectFood="selectSecondFood"
          @selectNum="selectNum"
          :foodInfo="item"
          :type="2"
          :foodNum="1"
        ></set-meal-sku-con>
      </div>
    </div>
    <!-- 加料 -->
    <div v-if="groupD">
      <div v-for="(item, index) in groupD.items" :key="index">
        <p class="title">
          {{ item.title }}
          <b class="mustNum">
            {{
              item.maxNum == item.minNum
                ? $store.state.language == 1
                  ? "必選"
                  : "Choose "
                : $store.state.language == 1
                ? "最多揀選 "
                : "Max "
            }}
            {{ item.maxNum }}
          </b>
        </p>
        <div class="mainFood" :ref="'item_' + index">
          <div class="foodItem" v-for="cell in item.cells" :key="cell.pid">
            <set-meal-sku-con
              :ref="'product_' + index + cell.pid"
              @selectFood="selectSecondFood"
              @selectNum="selectNum"
              @showTopNum="finish"
              :foodInfo="cell"
              :skuInfo="skuInfo"
              :type="4"
              :foodNum="
                updateSkuList[cell.pid + '_' + cell.indexItem] &&
                cell.indexItem ==
                  updateSkuList[cell.pid + '_' + cell.indexItem].indexItem
                  ? updateSkuList[cell.pid + '_' + cell.indexItem].num
                  : 0
              "
            ></set-meal-sku-con>
          </div>
        </div>
        <div v-if="item.cells.length > 3" class="moreBtn">
          <!-- <span :ref="'more_' + index">收起</span> -->
          <!-- <i></i> -->
        </div>
      </div>
    </div>

    <div class="foodSkuBox" id="box" :class="{ active: showSku }">
      <div class="foodSku">
        <div class="foodInfos">
          <img v-if="lanType == 1" :src="secondFood.logo" alt="" />
          <img v-else :src="secondFood.logoEn" alt="" />

          <p v-if="lanType == 1">{{ secondFood.name }}</p>
          <p v-else>{{ secondFood.nameEn }}</p>
        </div>
        <food-sku
          ref="skuItemFood"
          @selectSku="selectSku"
          v-for="item in numArr"
          :key="item"
          :index="item"
          :num="num"
          :skuInfo="secondFood.property.propertys"
        ></food-sku>
      </div>
      <div class="selectFinish" @click="closeSku">
        {{ $store.state.language == 1 ? "確認" : "Confirm" }}
      </div>
    </div>
    <confirm-box
      @finish="finish"
      v-show="showTopNum"
      :title="NUM_top"
      :finishBtn="finishBtn"
    ></confirm-box>
  </div>
</template>
<script>
import SetMealSkuCon from "./SetMealSkuCon.vue";
import FoodSku from "./FoodSku.vue";
import ConfirmBox from "../common/CnfirmBox.vue";
import { copyData } from "../../assets/utils/copy";
export default {
  name: "SetMealSku",
  data() {
    return {
      mainId: null, //主食id
      lanType: this.$store.state.language,
      foodList: [],
      otherFood: null,
      areaId: 0,
      numArr: [],
      num: 0, //数量
      showTopNum: false,
      showSku: false, //显示选择sku
      mainFoodAreas: null,
      groupB: null, //固定
      groupD: null, //加料
      selectedSku: {}, //封装的带有主键的可选餐品数据
      failFood: [], //不符合规范的餐品
      showFoodList: [], //展示一选择的餐品
      secondFood: {
        //有规格的单品
        name: "",
        nameEn: "",
        logo: "",
        logoEn: "",
        property: {
          standard: {},
        },
      },
      mealSku: {
        listPropertys: [],
      },
      NUM_top:
        this.$store.state.language == 1 ? "數量已達上限" : "Limit Exceeded",
      finishBtn: this.$store.state.language == 1 ? "確認" : "Confirm",
    };
  },
  props: {
    skuInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    mealNum: {
      type: Number,
      default: 1,
    },
    updateSkuList: {
      type: Object,
      default() {
        return {};
      },
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalMoney() {
      var total = 0;
      for (var i = 0; i < this.foodList.length; i++) {
        total += this.foodList[i].price * this.foodList[i].num;
      }
      return total;
    },
  },
  methods: {
    finish() {
      this.showTopNum = !this.showTopNum;
    },
    showFood(index) {
      var text = this.$refs["more_" + index].innerText;
      if (text == "展開" || text == "more") {
        this.$refs["item_" + index].classList.remove("active");
        this.$refs["more_" + index].innerText = "收起";
      } else {
        this.$refs["item_" + index].classList.add("active");
        var more = "展開";
        if (this.$store.state.language != 1) {
          more = "more";
        }
        this.$refs["more_" + index].innerText = more;
      }
    },
    // 选择主食
    selectMainFood(index, food) {
      this.mainIndex = index;
      this.foodList = [];
      food.listPropertys = [];
      this.mealSku = food;
      if (this.mainId == food.pid) {
        this.mainId = 0;
        this.foodList = [];
        return;
      } else {
        this.mainId = food.pid;
        this.foodList.push(food);
      }
      var extFood = this.mainFoodAreas.associatedGroups[food.pid];
      if (extFood.groupB) {
        this.groupB = extFood.groupB;
      }
      if (extFood.groupC) {
        this.groupC = extFood.groupC;
      }
      if (extFood.groupD) {
        this.groupD = extFood.groupD;
      }
    },
    // 封装数据
    getSelectedFood() {
      for (var i = 0; i < this.groupD.items.length; i++) {
        this.selectedSku["item_" + i] = {};
        this.selectedSku["item_" + i].maxNum = this.groupD.items[i].maxNum;
        this.selectedSku["item_" + i].minNum = this.groupD.items[i].minNum;
        this.selectedSku["item_" + i].maxNumCopy = this.groupD.items[i].maxNum;
        this.selectedSku["item_" + i].minNumCopy = this.groupD.items[i].minNum;
        this.selectedSku["item_" + i].title = this.groupD.items[i].title;
        this.selectedSku["item_" + i].cells = [];
        // this.selectedSku["item_" + i] = this.groupD.items[i];
        for (var a = 0; a < this.groupD.items[i].cells.length; a++) {
          // this.selectedSku["item_" + i].cells[a].mainKey = "item_" + i;
          this.groupD.items[i].cells[a].mainKey = "item_" + i;
        }
      }
      this.updateFunc();
    },
    // 将餐品放入封装的数据中
    setFoodNum(info) {
      if (!info.mainKey) {
        return;
      }
      // this.selectedSku[info.mainKey].maxNum =
      //   this.selectedSku[info.mainKey].maxNumCopy * this.mealNum;
      // this.selectedSku[info.mainKey].minNumCopy =
      //   this.selectedSku[info.mainKey].minNum * this.mealNum;
      var index = -1;
      for (var i = 0; i < this.selectedSku[info.mainKey].cells.length; i++) {
        if (this.selectedSku[info.mainKey].cells[i].pid == info.pid) {
          index = i;
          break;
        }
      }
      if (index != -1) {
        if (info.num == 0) {
          this.selectedSku[info.mainKey].cells.splice(i, 1);
        } else {
          this.selectedSku[info.mainKey].cells[i] = info;
        }
      } else {
        if (info.num > 0) {
          this.selectedSku[info.mainKey].cells.push(info);
        }
      }
    },

    // 是否已经达到最大值
    checkNumBox(info) {
      var total = 0;
      for (var i = 0; i < this.selectedSku[info.mainKey].cells.length; i++) {
        total += Number(this.selectedSku[info.mainKey].cells[i].num);
      }
      var index = info.mainKey.split("_")[1];
      this.$nextTick(() => {
        if (total >= this.selectedSku[info.mainKey].maxNum) {
          for (var a = 0; a < this.groupD.items[index].cells.length; a++) {
            this.$refs[
              "product_" + index + this.groupD.items[index].cells[a].pid
            ].changeActive(true, this.selectedSku[info.mainKey].maxNum,total);
          }
          return false;
        } else {
          for (var b = 0; b < this.groupD.items[index].cells.length; b++) {
            this.$refs[
              "product_" + index + this.groupD.items[index].cells[b].pid
            ].changeActive(false, this.selectedSku[info.mainKey].maxNum,total);
          }
          return true;
        }
      });
    },

    // 点击购物车时检查餐品是否合格在区间内
    checkFoodNum() {
      this.failFood = [];
      for (var key in this.selectedSku) {
        var totalNum = 0;
        for (var i = 0; i < this.selectedSku[key].cells.length; i++) {
          totalNum += Number(this.selectedSku[key].cells[i].num || 0);
        }
        if (
          totalNum < this.selectedSku[key].minNum ||
          totalNum > this.selectedSku[key].maxNum
        ) {
          this.failFood.push(this.selectedSku[key].title);
        }
      }
      if (this.failFood.length) {
        return this.failFood;
      } else {
        return null;
      }
    },
    // 选择副食的数量
    selectNum(info) {
      this.setFoodNum(info);

      this.checkNumBox(info);
      // if (!selectCan) return;
      this.numArr = [];
      this.num = Number(info.num);
      for (var i = 0; i < info.num; i++) {
        this.numArr.push(i);
      }
      var index = -1;
      for (var a = 0; a < this.foodList.length; a++) {
        if (
          this.foodList[a].pid == info.pid &&
          this.foodList[a].indexItem == info.indexItem
        ) {
          index = a;
          break;
        }
      }
      if (info.selected && info.num) {
        if (index == -1) {
          this.foodList.push(info);
        } else {
          this.foodList[index] = info;
        }
      } else {
        if (index != -1) {
          this.foodList.splice(index, 1);
        }
      }

      if (info.property) {
        // this.$toast("请选择规格，否则商品无效");
      }
      if (!this.update && info.property) {
        return;
      }
      var cndex = -1;
      for (var c = 0; c < this.mealSku.listPropertys.length; c++) {
        if (
          this.mealSku.listPropertys[c].pid == info.pid &&
          this.mealSku.listPropertys[c].indexItem == info.indexItem
        ) {
          cndex = c;
        }
      }
      if (info.selected && info.num) {
        if (cndex == -1) {
          // 将副食餐品放到主食下面
          this.mealSku.listPropertys.push(info);
        } else {
          // 如果副食已经存在 则替换副食
          this.mealSku.listPropertys[cndex] = info;
        }
      } else {
        if (cndex != -1) {
          this.mealSku.listPropertys.splice(cndex, 1);
        }
      }
      this.mealSku.myPrice = this.totalMoney;
      this.mealSku.index = this.index;
      this.$emit("getSku", this.mealSku);
    },
    //
    selectSecondFood(food) {
      if (food.property && food.property.propertys) {
        if (food.num != 0) {
          this.showSku = true;
        } else {
          for (var i = 0; i < this.mealSku.listPropertys.length; i++) {
            if (this.mealSku.listPropertys[i].pid == food.pid) {
              this.mealSku.listPropertys.splice(i, 1);
              this.mealSku.myPrice = this.totalMoney;
              this.$emit("getSku", this.mealSku);
              break;
            }
          }
          return;
        }
        this.secondFood = food;
        if (
          this.secondFood.listPropertys &&
          this.secondFood.listPropertys.length
        ) {
          this.secondFood.listPropertys.splice(this.secondFood.num);
        } else {
          this.secondFood.listPropertys = [];
        }
        this.$nextTick(() => {
          if (this.$refs.skuItemFood) {
            this.$refs.skuItemFood.clearSku();
            this.$refs.skuItemFood.defortSelect();
          }
        });
      }
    },
    // 关闭sku选择界面
    closeSku(check) {
      if (!check) {
        // if (e.target.id != "box")
        return;
      }
      if (
        this.secondFood.listPropertys &&
        this.secondFood.listPropertys.length != this.num
      ) {
        var A =
          this.$store.state.language == 1
            ? "無法加入購物車，尚有未選餐品"
            : "Unable to add to cart with unselected item(s)";
        this.$toast(A);
        return false;
      }
      if (!this.secondFood.listPropertys) {
        return true;
      }
      var index = -1;
      for (var i = 0; i < this.mealSku.listPropertys.length; i++) {
        if (this.mealSku.listPropertys[i].pid == this.secondFood.pid) {
          index = i;
          break;
        }
      }
      if (index == -1) {
        this.mealSku.listPropertys.push(this.secondFood);
      } else {
        this.mealSku.listPropertys[index] = this.secondFood;
      }
      this.mealSku.index = this.index;
      this.mealSku.myPrice = this.totalMoney;
      this.$emit("getSku", this.mealSku);
      // if (!check) {
      // if (e.target.id == "box") {
      this.showSku = false;
      // }
      // }
      return true;
    },
    // 选择的单品sku
    selectSku(info) {
      var iData = copyData(info);
      var index = -1;
      for (var i = 0; i < this.secondFood.listPropertys.length; i++) {
        if (this.secondFood.listPropertys[i].index == iData.index) {
          index = i;
        }
      }
      this.secondFood.typeId = 1;
      this.secondFood.weight = 0;
      if (index == -1) {
        this.secondFood.listPropertys.push(iData);
      } else {
        this.secondFood.listPropertys[index] = iData;
      }
    },
    // 修改套餐时执行
    updateFunc() {
      this.$nextTick(() => {
        if (this.update) {
          for (var key in this.updateSkuList) {
            if (!this.updateSkuList[key].groupB) {
              this.mealSku.listPropertys.push(this.updateSkuList[key]);
              this.foodList.push(this.updateSkuList[key]);
              this.setFoodNum(this.updateSkuList[key]);
            }
          }
          this.mealSku.index = this.index;
          this.mealSku.myPrice = this.totalMoney;
          this.$emit("getSku", this.mealSku);
        }
      });
    },
  },
  watch: {
    mealNum() {
      // for (var key in this.selectedSku) {
      //   this.selectedSku[key].maxNum =
      //     this.selectedSku[key].maxNumCopy * this.mealNum;
      //   this.selectedSku[key].minNumCopy =
      //     this.selectedSku[key].minNum * this.mealNum;
      // }
    },
    skuInfo() {
      this.mainFoodAreas = this.skuInfo.mainFoodAreas[0];
      if (this.mainFoodAreas.associatedGroups[0].groupB) {
        this.groupB = this.mainFoodAreas.associatedGroups[0].groupB;
      }
      if (this.mainFoodAreas.associatedGroups[0].groupD) {
        this.groupD = this.mainFoodAreas.associatedGroups[0].groupD;
        this.getSelectedFood();
      }
    },
    groupB(val) {
      for (var i = 0; i < val.cells.length; i++) {
        val.cells[i].num = 1;
        val.cells[i].groupB = true;
        this.foodList.push(val.cells[i]);
        this.showFoodList.push(val.cells[i]);
        this.mealSku.listPropertys.push(val.cells[i]);
      }
      this.mealSku.index = this.index;
      this.mealSku.myPrice = this.totalMoney;
      this.$emit("getSku", this.mealSku);
      // if (this.update) {
      //   for (var key in this.updateSkuList) {
      //     if (!this.updateSkuList[key].groupB) {
      //       this.mealSku.listPropertys.push(this.updateSkuList[key]);
      //       this.foodList.push(this.updateSkuList[key]);
      //       this.setFoodNum(this.updateSkuList[key]);
      //     }
      //   }
      //   this.mealSku.index = this.index;
      //   this.mealSku.myPrice = this.totalMoney;
      //   this.$emit("getSku", this.mealSku);
      // }
    },
  },
  components: {
    SetMealSkuCon,
    FoodSku,
    ConfirmBox,
  },
};
</script>
<style scoped>
.setMealPage {
  padding: 0.01rem 0.16rem 0.5rem;
  box-sizing: border-box;
}

.title {
  height: 0.17rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.19rem;
  color: #03060d;
  text-align: left;
  margin-top: 0.28rem;
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mustNum {
  padding: 0.04rem 0.08rem;
  color: #e4022b;
  font-size: 0.12rem;
  background-color: rgba(228, 2, 43, 0.1);
  border-radius: 0.3rem;
  margin-left: 0.08rem;
}
.selectedFoodItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.08rem;
}
.cover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cover img {
  width: 0.44rem;
  height: 0.44rem;
  object-fit: cover;
  margin-right: 0.08rem;
}
.cover p {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #030f14;
}
.num {
  font-size: 0.16rem;
  font-weight: 400;
  color: #030f14;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: right;
}
.mainFood {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}
.mainFood.active {
  max-height: 1.07rem;
  overflow: hidden;
}
.mainFood .foodItem {
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
  position: relative;
}
.mainFood .foodItem:nth-child(3n) {
  margin-right: 0;
}
.selectIcon {
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  top: 0.04rem;
  right: 0.04rem;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  z-index: 999;
}
.selectIcon.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.foodSkuBox {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: -100vh;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.2s linear;
  z-index: 999999;
}
.foodSkuBox.active {
  bottom: 0;
}
.foodSku {
  width: 100%;
  height: 4rem;
  background-color: #fff;
  border-radius: 0.12rem 0.12rem 0 0;
  position: absolute;
  bottom: 0;
  overflow-y: scroll;
  padding-bottom: 0.8rem;
  box-sizing: border-box;
}
.foodInfos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.foodInfos img {
  width: 40%;
  max-height: 1.1rem;
  object-fit: cover;
  margin: auto;
}
.foodInfos p {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  margin-top: 0.16rem;
}
.guding {
  display: none;
}
.selectFinish {
  width: 3.43rem;
  height: 0.44rem;
  border-radius: 0.08rem;
  line-height: 0.44rem;
  text-align: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  background-color: #e4022b;
  margin-left: 0.16rem;
  /* margin-top: 0.1rem; */
  position: absolute;
  bottom: 0.3rem;
}
.moreBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  color: #b1b1b3;
  /* margin-top: 0.6rem;
  margin-bottom: 0.24rem; */
}
.moreBtn i {
  width: 0.1rem;
  height: 0.05rem;
  background: url("../../assets/icon/top.png") no-repeat center;
  background-size: cover;
  margin-left: 0.08rem;
}
</style>